import axios from "axios";
import { toast } from "react-toastify";

export const HTTPService = async ({
  url,
  method,
  baseURL = "https://gc-api-v2.greenchoicenow.com/api",
  data = {},
  headers = null,
  params = {},
  timeout = 10000
}) => {
  try {
    let response = await axios({
      url: baseURL + url,
      method,
      data,
      headers,
      params,
      timeout,
    });

    return response.data;
  } catch (ex) {
    // Handle API errors
   if (ex.code === "ECONNABORTED") {
      // Handle timeout errors
      toast.error("Request timed out. Please check your network and try again.");
      throw new Error("Request timed out. Please check your network and try again.");
    } 
  }
};
