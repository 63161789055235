import {create} from "zustand";
import { persist } from "zustand/middleware";

export const useProductStore = create(
    (set) => ({
      products: [],
      setProducts: (products) => set({ products: products }),
    }),
    {
      name: "product-store",
    }
);
