import React, { useEffect, useRef, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faTimes } from '@fortawesome/free-solid-svg-icons';
import { autoComplete } from '../../APIs/functions';
import Suggestions from '../../Components/SearchSuggestions';
import Listing from '../ListingPage';
import { useSearch } from '../../Context/SearchContext';
import FilterSelector from '../../Components/FilterSelector';
import SearchBottomSheet from '../../Components/SearchFilter';
import { formatTotalResults } from '../../Utils/CommonFunctions';
import { useProductStore } from '../../Store/productStore';
import './index.scss';
import { Link } from 'react-router-dom';

const mostSearchedProducts = [
    "plant based milk",
    "breakfast food",
    "pasta",
    "chips",
    "cereals",
    "vegan snack bars",
    "gluten free",
    "low sodium nuts"
];

const Search = () => {
    const { searchState, setSearchState } = useSearch();

    const [searchQuery, setSearchQuery] = useState(searchState.searchQuery || "");
    const [debouncedSearchTerm, setDebouncedSearchTerm] = useState('');
    const [suggestions, setSuggestions] = useState({});
    const [isSearchReady, setIsSearchReady] = useState(searchState.isSearchReady || false);
    const [totalResults, setTotalResults] = useState(0);
    const [searchType, setSearchType] = useState("");
    const [typing, setTyping] = useState(false);
    const setProducts = useProductStore((state) => state.setProducts);

    const debounceTimeoutRef = useRef(null);

    const resetState = () => {
        setSearchQuery("");
        setDebouncedSearchTerm('');
        setSuggestions({});
        setIsSearchReady(false);
        setTotalResults(0);
        setSearchType("");
        setTyping(false);
        setProducts([]);
        setSearchState((prev) => ({
            ...prev,
            searchQuery: "",
            suggestions: {},
            isSearchReady: false,
            searchType: "",
            currentPage: 1,
            lastPosition: 0
        }));
    };

    useEffect(() => {
        if (debouncedSearchTerm) {
            fetchSuggestions(debouncedSearchTerm);
        } else {
            setSuggestions({});
        }
    }, [debouncedSearchTerm]);

    useEffect(() => {
        return () => {
            if (debounceTimeoutRef.current) {
                clearTimeout(debounceTimeoutRef.current);
            }
        };
    }, []);

    useEffect(() => {
        setSearchQuery(searchState.searchQuery);
        setSuggestions(searchState.suggestions || {});
    }, [searchState]);

    const handleSearch = (text) => {
        setTyping(true);
        setSearchQuery(text);
        setSearchState((prev) => ({ ...prev, searchQuery: text }));
        setProducts([]);
        setTotalResults(0)
        if (debounceTimeoutRef.current) {
            clearTimeout(debounceTimeoutRef.current);
        }
        debounceTimeoutRef.current = setTimeout(() => {
            setDebouncedSearchTerm(text);
        }, 300);
    };

    const fetchSuggestions = async (term) => {
        const data = { term };
        const res = await autoComplete(data);
        const updatedRes = {
            productcategory: res?.productcategory,
            productsubcategory: res?.productsubcategory,
            producttype: res?.producttype,
        };
        setSuggestions(updatedRes);
    };

    const handleMove = (item = searchType, title = "productname") => {
        setTyping(false);
        setSearchType(title);
        setIsSearchReady(true);
        setSearchState((prevState) => ({
            ...prevState,
            searchQuery: item,
            searchType: title,
            currentPage: 1,
            isSearchReady: true,
            lastPosition: 0
        }));
    };

    const EmptyState = () => {
        return (
            <div className="empty-state">
                <FontAwesomeIcon icon={faSearch} size="2xl" />
                <p className="empty-text">
                   Search food & beverage products at this store
                </p>
                {/* <p className="helper-text">
                </p> */}
            </div>
        );
    };

    return (
        <div className="search-container">
            <div className='fixed-head'>
                <h3 className='d-flex align-items-center gap-2 mb-0'> Search
                </h3>
                <div className="w-100 position-relative">
                    <FontAwesomeIcon className='input-icon' icon={faSearch} size="md" />
                    <input
                        type="text"
                        placeholder="Search product, brand, or food type"
                        value={searchQuery}
                        onChange={(e) => handleSearch(e.target.value)}
                        className="search-input"
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                handleMove(e.target.value)
                                e.target.blur();
                            }
                        }}
                    />
                    {searchQuery && (
                        <FontAwesomeIcon
                            className='clear-icon'
                            icon={faTimes}
                            size="md"
                            onClick={() => {
                                resetState();
                            }}
                        />
                    )}
                </div>

               <SearchBottomSheet />

            </div>

           <section className='search-suggestions'>
                {(searchQuery && searchQuery.length < 3) && (
                    <div className="most-searched my-2">
                        <h3 className="most-searched-title mt-2">Most Searched</h3>
                        <div className="most-searched-list">
                            {mostSearchedProducts.map((product) => (
                                <div
                                    className="most-searched-item"
                                    key={product}
                                    onClick={() => handleMove(product)}
                                >
                                    <p
                                        className="most-searched-link mb-0"
                                    >
                                        {product}
                                    </p>
                                </div>
                            ))}
                        </div>
                    </div>
                )}
    
                {suggestions &&
                    typing &&
                    <Suggestions
                        suggestions={suggestions}
                        handleMove={handleMove}
                    />
                }
           </section>


            {(!suggestions || !searchQuery) && <EmptyState />}

            {!typing && isSearchReady &&
                <Listing
                    search={searchQuery}
                    type={searchType}
                    setTotalResults={setTotalResults}
                    totalResults={totalResults}
                />
            }
        </div>
    )
}

export default Search
