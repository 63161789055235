import React, { useEffect, useMemo, useState } from "react";
import SearchProductCard from "../../Components/SearchProductCard";
import { searchProducts } from "../../APIs/functions";
import { useFilters } from "../../Context/FiltersContext";
import { useSearch } from "../../Context/SearchContext";
import { Spin } from "antd";
import InfiniteScroll from "react-infinite-scroll-component";
import "./index.scss";
import { debounce, isEqual } from "lodash";
import { useProductStore } from "../../Store/productStore";
import { getClientInfoFromLocalStorage } from "../../Utils/ClientInfo";
import { handleSearch } from "../../Gtag";

const SearchListing = ({ search, type, totalResults, setTotalResults }) => {
  const { searchState, setSearchState } = useSearch();

  const clientInfo = getClientInfoFromLocalStorage();

  const isDev = clientInfo?.name === "" || clientInfo?.name === "qrdemo";

  const products = useProductStore((state) => state.products);
  const setProducts = useProductStore((state) => state.setProducts);

  const [currentPage, setCurrentPage] = useState(searchState.currentPage || 1);
  const [loading, setLoading] = useState(false);

  const { selectedFilters, getFilterLabelById } = useFilters();

  const buildFilterPayload = (selectedFilters) => {
    const excludeArr = [];
    const includeAllSet = new Set();
    const values = [];
    const includeOrSet = new Set();

    const handledIngredientTags = new Set(); // Track added ingredient tags

    Object.keys(selectedFilters).forEach((compositeKey) => {
      const [category, id] = compositeKey.split("_");
      const filterLabel = getFilterLabelById(
        category,
        parseInt(id)
      ).toLowerCase();

      if (category === "diets") {
        excludeArr.push({ dietconflicts: filterLabel });
        if (
          filterLabel === "no added sugar" &&
          !handledIngredientTags.has(filterLabel)
        ) {
          excludeArr.push({ ingredienttags: filterLabel });
          handledIngredientTags.add(filterLabel);
        }
      } else if (category === "allergies") {
        excludeArr.push({ allergyconflicts: filterLabel });
      } else if (category === "nutrients") {
        includeAllSet.add(JSON.stringify({ nutritiontags: filterLabel }));
      } else if (category === "sustainability") {
        const tagsToAdd = [];
        let handled = false;

        if (filterLabel === "100% organic ingredients") {
          tagsToAdd.push("USDA 100% Organic Claim Derived");
          handled = true;
        } else if (filterLabel === "95%+ organic ingredients") {
          tagsToAdd.push(
            "USDA Organic Claim Derived",
            "USDA 100% Organic Claim Derived"
          );
          handled = true;
        } else if (filterLabel === "70%+ organic ingredients") {
          tagsToAdd.push(
            "USDA Made With Organic Claim Derived",
            "USDA Organic Claim Derived",
            "USDA 100% Organic Claim Derived"
          );
          handled = true;
        } else if (filterLabel === "contains organic ingredients") {
          tagsToAdd.push(
            "Contains organic ingredients",
            "USDA Made With Organic Claim Derived",
            "USDA Organic Claim Derived",
            "USDA 100% Organic Claim Derived"
          );
          handled = true;
        }

        if (handled) {
          tagsToAdd.forEach((tag) =>
            includeOrSet.add(JSON.stringify({ sustainabilitytagsarr: tag }))
          );
        } else {
          includeAllSet.add(
            JSON.stringify({ sustainabilitytags: filterLabel })
          );
        }
      } else if (category === "ingredients") {
        if (!handledIngredientTags.has(filterLabel)) {
          if (
            filterLabel.includes("100% whole grain") ||
            filterLabel.includes("contains whole grain")
          ) {
            includeAllSet.add(JSON.stringify({ ingredienttags: filterLabel }));
          } else {
            excludeArr.push({ ingredienttags: filterLabel });
          }
          handledIngredientTags.add(filterLabel);
        }
      } else if (category === "values") {
        values.push(filterLabel);
      }
    });

    const includeAllArr = Array.from(includeAllSet).map((item) =>
      JSON.parse(item)
    );
    const includeOrArr = Array.from(includeOrSet).map((item) =>
      JSON.parse(item)
    );

    return {
      excludeArr,
      includeAllArr,
      values,
      includeOrArr,
    };
  };

  const getSearchedItems = async (page = 1) => {
    setLoading(true);
    let reqBodyType =
      type === "productname" || type === "productbrand" ? "" : type;
    let reqBodyTerm = type === "productbrand" ? "" : search;
    let reqBodyBrand = type === "productbrand" ? search : null;

    const { excludeArr, includeOrArr, includeAllArr, values } =
      buildFilterPayload(selectedFilters);

    const reqBody = {
      current: page,
      excludeArr: excludeArr,
      includeAllArr: includeAllArr,
      includeOrArr: includeOrArr,
      includeArr: [{ stores: "research" }],
      packs: [],
      range: [],
      size: isDev ? 24 : 999,
      sort: {},
      term: reqBodyTerm.toLowerCase(),
      type: reqBodyType,
      brand: reqBodyBrand,
      values: values,
      client: isDev
        ? ""
        : clientInfo?.name === "dev-qr"
        ? "dev_qr"
        : clientInfo?.name,
      location: isDev ? "" : clientInfo?.location,
    };

    try {
      handleSearch(reqBodyTerm.toLowerCase());
      const res = await searchProducts(reqBody);
      const results = Array.isArray(res?.results) ? res?.results : [];
      const productList = page === 1 ? results : [...products, ...results];
      setProducts(productList);
      setTotalResults(res?.meta?.page?.total_results);
      setSearchState((prevState) => ({
        ...prevState,
        currentPage: page,
        selectedFilters: selectedFilters,
      }));

      // Automatically fetch next page if less than 10 results and not empty
      if (
        results.length > 0 &&
        results.length < 10 &&
        res?.meta?.page?.current < res?.meta?.page?.total_pages
      ) {
        const nextPage = page + 1;
        setCurrentPage(nextPage);
        await getSearchedItems(nextPage);
      }
    } catch (error) {
      console.error("Error fetching products:", error);
      throw error;
    } finally {
      setLoading(false);
    }
  };

  const fetchMoreData = async () => {
    const nextPage = currentPage + 1;
    setCurrentPage(nextPage);
    await getSearchedItems(nextPage);
  };

  const handleUpdatePosition = (position) => {
    setSearchState((prevState) => ({
      ...prevState,
      lastPosition: Math.floor(position),
    }));
  };

  const debouncedUpdatePosition = useMemo(
    () => debounce(handleUpdatePosition, 1000),
    [handleUpdatePosition]
  );

  useEffect(() => {
    const filtersChanged = !isEqual(
      selectedFilters,
      searchState.selectedFilters
    );
    if (
      products.length > 0 &&
      search === searchState.searchQuery &&
      !filtersChanged
    )
      return;
    const debounceTimeout = setTimeout(() => {
      if (search) {
        setCurrentPage(1);
        getSearchedItems(1);
      }
    }, 500);

    return () => clearTimeout(debounceTimeout);
  }, [search, type, selectedFilters, products.length, searchState.searchQuery]);

  const [noProducts, setNoProducts] = useState(false);

  useEffect(() => {
    if (!loading && products.length === 0) {
      const timer = setTimeout(() => {
        setNoProducts(true);
      }, 500);

      return () => clearTimeout(timer);
    } else {
      setNoProducts(false);
    }
  }, [loading, products.length]);

  const renderProducts = () => {
    if (loading && products.length === 0) {
      return <p className="no-products-text">Researching...</p>;
    }

    if (loading && currentPage === 1) {
      return <p className="no-products-text">Researching...</p>;
    }

    if (noProducts) {
      return <p className="no-products-text">No products found</p>;
    }

    return (
      <InfiniteScroll
        dataLength={products.length}
        next={fetchMoreData}
        scrollThreshold={0.6}
        style={{ paddingBottom: "6rem" }}
        height="calc(100vh - 290px)"
        initialScrollY={
          searchState.lastPosition > 0 ? searchState.lastPosition : 0
        }
        hasMore={products.length < totalResults}
        onScroll={(e) => {
          const scrollTop = e.target.scrollTop;
          debouncedUpdatePosition(scrollTop);
        }}
        loader={
          <div className="d-flex align-items-center justify-content-center w-100 mx-auto py-2">
            <Spin />
          </div>
        }
      >
        <div className="product-grid">
          {products?.map((product, index) => (
            <SearchProductCard key={index} product={product} />
          ))}
        </div>
      </InfiniteScroll>
    );
  };

  return (
    <div className="search-listing">
      <div className="product-list">{renderProducts()}</div>
    </div>
  );
};

export default SearchListing;
