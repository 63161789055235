import React, { useEffect, useState } from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import Header from '../../Components/Header'
import Footer from '../../Components/Footer'
import ScrollToTop from '../../Components/ScrolltoTop'
import { ErrorBoundary } from 'react-error-boundary';
import NotFound from '../NotFound'
import Navigation from '../../Components/Navigation'
import './index.scss';
import { getClientInfoFromLocalStorage } from '../../Utils/ClientInfo'

function ErrorFallback({ error, resetErrorBoundary }) {
  function handleReset() {
    window.location.reload();
  }
  return (
    <div className="error-fallback">
      <div className="container">
        <h2>Oops! Something went wrong.</h2>
        <p>We're sorry, but an unexpected error occurred:</p>
        <pre>{error.message}</pre>
        <button onClick={handleReset}>Try again</button>
      </div>
    </div>
  );
}


const Layout = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [isMobile, setIsMobile] = useState(false);
  const isSpecificPage = location.pathname === '/preferences-setup' || location.pathname === "/scan";

  const clientInfo = getClientInfoFromLocalStorage();

  useEffect(() => {
    var x = window.matchMedia("(max-width: 767px)");
    if (x.matches) {
      setIsMobile(true);
      if (location.pathname === "/" && clientInfo?.scanEnabled === "enabled")
        navigate("/history");
    } else {
      setIsMobile(false);
    }
  }, []);

  return (
    <>
      <Header />
      <ErrorBoundary FallbackComponent={NotFound}>
        <main className={location.pathname === "/scan" ? "mb-0" : "mb-5"}>
          <ScrollToTop />
          <Outlet />
        </main>
      </ErrorBoundary>
      {!isMobile && <Footer disabled={isSpecificPage} />}
      {isMobile && clientInfo?.scanEnabled === "enabled" && <Navigation />}
    </>
  )
}

export default Layout