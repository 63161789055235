import React, { useState } from 'react';
import { Drawer } from 'antd';
import BottomSheetCard from '../BottomSheetProductCard';
import BottomSheetFilters from '../BottomSheetFilters';
import "./index.scss"

const BottomSheet = ({ visible, onClose, productData , productDetails, productDbData, handleResetCamera}) => {
    const [filtered, setFiltered] = useState(false)
    const [startY, setStartY] = useState(null);

    const handleTouchStart = (e) => {
        setStartY(e.touches[0].clientY);
    };

    const handleTouchEnd = (e) => {
        if (!startY) return;
        const endY = e.changedTouches[0].clientY;
        const deltaY = endY - startY;

        if (deltaY > 50) {
            onClose();
            handleResetCamera();
        }

        setStartY(null);
    };

    return (
        <Drawer
            className={"product-bottom-sheet"}
            placement="bottom"
            rootClassName='bottom-sheet-wrap'
            closable={true}
            mask={false}
            destroyOnClose={true}
            onClose={onClose}
            open={visible}
            height={"auto"}
            closeIcon={null}
            headerStyle={{ display: 'none' }}
            bodyStyle={{ touchAction: 'none' }} 
        >
           <div
                onTouchStart={handleTouchStart} 
                onTouchEnd={handleTouchEnd}>
               {Number(productData?.activeGsSystem) >= 4 && <BottomSheetFilters product={productDetails} setFiltered={setFiltered} />}
               <BottomSheetCard
                    image={productDbData ? productDbData?.productImage : productData?.productImage}
                    productName={productDbData ? productDbData?.productName : productData?.productName}
                    totalGs={productDbData ? productDbData?.totalGs : productData?.totalGs}
                    url={productDbData ? productDbData?.productNameURL : productData?.productNameURL}
                /> 
           </div>
        </Drawer>
    );
};

export default React.memo(BottomSheet);
