import React, { createContext, useState, useContext } from "react";

const SearchContext = createContext();

export const SearchProvider = ({ children }) => {
    const [searchState, setSearchState] = useState({
        searchQuery: "",
        searchType: "",
        selectedFilters: {},
        lastPosition: 0,
        currentPage: 1,
        isSearchReady: false,
    });

    const resetSearchState = () => {
        setSearchState({
            searchQuery: "",
            searchType: "",
            selectedFilters: {},
            lastPosition: 0,
            currentPage: 1,
            isSearchReady: false,
        })
    }   

    return (
        <SearchContext.Provider value={{ searchState, setSearchState, resetSearchState }}>
            {children}
        </SearchContext.Provider>
    );
};

export const useSearch = () => useContext(SearchContext);
