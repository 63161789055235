import Dexie from 'dexie';

export const db = new Dexie('gc-db');
db.version(1).stores({
  favorites: 'id',         
  savedProducts: 'id',      
});

// Initialize the database by opening it.
const initDB = async () => {
  try {
    await db.open();
  } catch (error) {
    console.error('Failed to open Dexie DB:', error);
  }
  return db;
};

// export const saveProductsDBVersionToDB = async (version) => {
//   await initDB();
//   await db.ProductsDBversion.put({ id: 'latestVersion', version });
// };

// export const getProductsDBVersionFromDB = async () => {
//   await initDB();
//   const versionEntry = await db.ProductsDBversion.get('latestVersion');
//   return versionEntry ? versionEntry.version : null;
// };

// export const getProductByUPC = async (upcCode) => {
//   await initDB();
//   return await db.ProductsDatabase.get(upcCode);
// };

export const deleteOldDB = async () => {
  try {
    await Dexie.delete('gc-db');
    console.log('Old Database deleted');
  } catch (error) {
    console.error('Error deleting old DB:', error);
  }
};

export const addFavoriteToDB = async (product) => {
  await initDB();
  await db.favorites.put({ id: product?.upcCode, product });
};

export const removeFavoriteFromDB = async (productId) => {
  await initDB();
  await db.favorites.delete(productId);
};

export const addRecentScanToDB = async (product) => {
  await initDB();
  const timestamp = new Date().toISOString();
  // Save the product with a timestamp
  await db.savedProducts.put({
    id: product?.upcCode,
    product: { timestamp, ...product }
  });

  const allItems = await db.savedProducts.toArray();
  if (allItems.length > 100) {
    allItems.sort((a, b) => new Date(a.product.timestamp) - new Date(b.product.timestamp));
    const oldestItem = allItems[0];
    await db.savedProducts.delete(oldestItem.id);
  }
};

export const getAllFavoritesFromDB = async () => {
  await initDB();
  return await db.favorites.toArray();
};

export const getAllRecentlyScannedFromDB = async () => {
  await initDB();
  const allItems = await db.savedProducts.toArray();
  return allItems.sort((a, b) => new Date(b.product.timestamp) - new Date(a.product.timestamp));
};

export default initDB;
