import { motion } from "framer-motion";

const pageVariants = {
  initial: { y: 20 },
  animate: { y: 0, transition: { duration: 0.3, ease: "easeOut" } },
  exit: { y: -20, transition: { duration: 0.2, ease: "easeOut" } },
};

export const PageWrapper = ({ children, disableAnimation = false }) => {
  return (
    <motion.div
      initial={disableAnimation ? false : "initial"}
      animate={disableAnimation ? false : "animate"}
      exit={disableAnimation ? false : "exit"}
      variants={disableAnimation ? {} : pageVariants}
    >
      {children}
    </motion.div>
  );
};
