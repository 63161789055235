import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { similarProducts } from "../../APIs/functions";
import ProductCarousel from "../ProductCarousel";
import "./index.scss";
import ClientProductCarousel from "../ClientProductCarousel";
import { Spin } from "antd";
import FilterSelector from "../FilterSelector";
import { useFilters } from "../../Context/FiltersContext";

const ProductPairing = ({ product }) => {
  const { selectedFilters } = useFilters()
  const { productNameURL, productName } = product;
  const url = new URL(window.location.href);
  const queries = {
    refetchOnWindowFocus: false,
    refetchOnmount: false,
  };

  const hostnameParts = url.hostname.split('.');
  const client = hostnameParts.length > 2 ? hostnameParts.slice(0, hostnameParts.length - 2).join('.') : '';

  let clientParam = ""

  if (client === "" || client === "qrdemo" || client === undefined) {
    clientParam = ""
  } else {
    clientParam = client
  }

  const [filtersParam, setFiltersParams] = useState({
    diets: "",
    allergies: "",
    valueFilters: "",
    ingredientFilters: "",
    sustainabilityFilters: "",
    nutrientFilters: ""
  })

  const [filtersReady, setFiltersReady] = useState(false);

  function getLocationIdParam(url) {
    let locationIdParam = url.searchParams.get('location_id');

    if (locationIdParam) {
      locationIdParam = locationIdParam.split('?')[0];
    }

    return locationIdParam;
  }

  const userLocation = getLocationIdParam(url);

  useEffect(() => {

    let allDiets = [];
    let allAllergies = [];
    let allValues = [];
    let allIngredients = [];
    let allSustainabilities = [];
    let allNutrients = [];

    // Extract all filter types if present in filters, else default to empty
    Object.keys(selectedFilters).forEach(item => {
      if (item.includes("diet")) {
        allDiets.push(item.split("_")[1]);
      }
      if (item.includes("allerg")) {
        allAllergies.push(item.split("_")[1]);
      }
      if (item.includes("value")) {
        allValues.push(item.split("_")[1]);
      }
      if (item.includes("ingredient")) {
        allIngredients.push(item.split("_")[1]);
      }
      if (item.includes("sustainability")) {
        allSustainabilities.push(item.split("_")[1]);
      }
      if (item.includes("nutrient")) {
        allNutrients.push(item.split("_")[1]);
      }
    });

    // Update the filtersParam state with joined values or empty strings
    setFiltersParams({
      diets: allDiets.join(","),
      allergies: allAllergies.join(","),
      valueFilters: allValues.join(","),
      ingredientFilters: allIngredients.join(","),
      sustainabilityFilters: allSustainabilities.join(","),
      nutrientFilters: allNutrients.join(",")
    });

    setFiltersReady(true);

    return () => {
      setFiltersReady(false)
      setFiltersParams({
        diets: "",
        allergies: "",
        valueFilters: "",
        ingredientFilters: "",
        sustainabilityFilters: "",
        nutrientFilters: ""
      })
    }

  }, [selectedFilters]);

  // const [isRefferer, setIsRefferer] = useState(false);

  // useEffect(() => {
  //   const referringSite = document.referrer;
  //   if (referringSite.includes('https://marquette.storebyweb.com/')) {
  //     setIsRefferer(true);
  //   }
  // }, []);

  const {
    data: pairProducts,
    isLoading: pairProductsLoading,
    isError: pairProductsError
  } = useQuery(
    ["pairProducts", productNameURL, clientParam, userLocation, filtersParam],
    () => similarProducts({ productNameURL, clientParam, userLocation, filtersParam }),
    { ...queries, enabled: filtersReady }
  );

  const products = (!pairProductsLoading && !pairProductsError && Array.isArray(pairProducts)) ? pairProducts : (Array.isArray(pairProducts?.data) ? pairProducts.data : []);

  const productsModified = products.length > 1 ? products : []

  if (!pairProductsLoading && !pairProductsError && productsModified.length <= 1) {
    return null
  }

  return (
    <>
      {!pairProductsLoading ? (
        <section id="productPairing">
          <div className="container overflow-hidden">
            <div className="text-center alternative-title">
              <h2> Product pairings available at this store </h2>
            </div>
            <FilterSelector key="pairing-selector" position={null} size={null} />

            {productsModified !== null && <ProductCarousel
              carousel={"similar"}
              items={productsModified.slice(1)}
            />}
            {/* {!isRefferer ? productWithImages !== null && (
              <ProductCarousel
                carousel={"similar"}
                items={productWithImages}
              />
            ) : <ClientProductCarousel
              carousel={"similar"}
              items={sortedProducts}
            />} */}
          </div>
        </section>
      ) : <section id="productPairing">
        <div className="container overflow-hidden">
          <div className="text-center alternative-title">
            <h2> Product pairings available at this store </h2>
          </div>
          <FilterSelector position={null} size={null} />
          <div className="d-flex justify-content-center align-items-center text-center h-100 py-5">
            <Spin fullscreen={false} size="small" />
          </div>
        </div>
      </section>}
    </>
  );
};

export default ProductPairing;
