import { createBrowserRouter, Navigate } from "react-router-dom";
import { getClientInfoFromLocalStorage } from "../Utils/ClientInfo";
import Layout from "../Pages/Layout";
import ProductDetailPage from "../Pages/ProductDetailPage";
import Glossary from "../Pages/Glossary";
import Greenscore from "../Pages/Greenscore";
import Terms from "../Pages/Terms";
import NotFound from "../Pages/NotFound";
import Filters from "../Pages/Filters";
import Scan from "../Pages/Scan";
import Favorite from "../Pages/Favorite";
import History from "../Pages/History";
import Search from "../Pages/Search";

const ProtectedRoute = ({ children }) => {
  const clientInfo = getClientInfoFromLocalStorage();
  
  if (clientInfo.scanEnabled !== "enabled") {
    return <Navigate to="/" replace />;
  }

  return children;
};

export const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    children: [
      {
        path: "/p/:name",
        element: <ProductDetailPage />,
      },
      {
        path: "/glossary",
        element: <Glossary />,
      },
      {
        path: "/greenscore",
        element: <Greenscore />,
      },
      {
        path: "/terms",
        element: <Terms />,
      },
      {
        path: "/preferences-setup",
        element: <Filters />
      },
      {
        path: "/scan",
        element: (
          <ProtectedRoute>
            <Scan />
          </ProtectedRoute>
        )
      },
      {
        path: "/favorite",
        element: (
          <ProtectedRoute>
            <Favorite />
          </ProtectedRoute>
        )
      },
      {
        path: "/history",
        element: (
          <ProtectedRoute>
            <History />
          </ProtectedRoute>
        )
      },
      {
        path: "/search",
        element: (
          <ProtectedRoute>
            <Search />
          </ProtectedRoute>
        )
      }
    ],
  },
  {
    path: "*",
    element: <NotFound />
  }
]);
