import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Drawer, Modal } from 'antd';
import { getProductByUPC, getProductDataFromUPC, getProductName, getScanData } from '../../APIs/functions';
import BottomSheet from '../BottomSheet';
import { useLocation, useNavigate } from 'react-router-dom';
import FilterSelector from '../FilterSelector';
import { addRecentScanToDB } from '../../db/db';
import placeholderimg from '../../Assets/Images/placeholder.png';
import './index.scss';

const successSound = new Audio('/sounds/success.mp3');
const errorSound = new Audio('/sounds/error.mp3');

const ScanResults = ({ scannedBarcode , invalidCode}) => {
  const [result, setResult] = useState(null);
  const [product, setProduct] = useState(null);
  const [dbProduct, setDbProduct] = useState(null);
  const [productDetails, setProductDetails] = useState(null);
  const [isDrawerVisible, setDrawerVisible] = useState(false);
  const [isErrorModalVisible, setErrorModalVisible] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [firstScanModalVisible, setFirstScanModalVisible] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);

  // Refs to store last processed codes and a debounce timer for QR scans.
  const lastScannedUPC = useRef(null);
  const lastScannedQR = useRef(null);
  const qrDebounceTimer = useRef(null);

  const navigate = useNavigate();
  const location = useLocation();

  const resetProductStates = useCallback(() => {
    setDrawerVisible(false);
    setProduct(null);
    setDbProduct(null);
    setProductDetails(null);
    lastScannedUPC.current = null;
    lastScannedQR.current = null;
  }, []);

  // Helper: returns DB result or null after a timeout.
  const getProductByUPCWithTimeout = async (upcCode, timeout = 200) => {
    return Promise.race([
      getProductByUPC(upcCode),
      new Promise((resolve) => setTimeout(() => resolve(null), timeout))
    ]);
  };

  const getProductNameFromQR = async (number) => {
    setIsProcessing(true);
    try {
      const res = await getProductName({ id: number });
      let upcCode = null;
      if(Array.isArray(res) && res.length > 0) {
        upcCode = res[0].upcCode
      } else {
        upcCode = res?.data?.upcCode;
      }
      if (upcCode) {
        // Set result only once per unique QR.
        setResult(upcCode);
      } else {
        setErrorModalVisible(true);
        setErrorMessage("Oops! We couldn't find the product you scanned. Please try a different one.");
        errorSound.play();
        resetProductStates();
        return;
      }
    } catch (error) {
      console.error(error);
      setErrorMessage(error?.message || "Something went wrong!");
      setErrorModalVisible(true);
      resetProductStates();
      errorSound.play();
      return;
    } finally {
      setIsProcessing(false);
    }
  };

  const handleBottomSheetClose = useCallback(() => {
    setDrawerVisible(false);
    setProduct(null);
    setProductDetails(null);
    setDbProduct(null);
  }, []);

  useEffect(() => {
    if (!scannedBarcode || isProcessing) return;

    if (scannedBarcode.type !== 'qr') {
      // For UPC scans: clear any pending QR debounce and process immediately.
      if (qrDebounceTimer.current) {
        clearTimeout(qrDebounceTimer.current);
        qrDebounceTimer.current = null;
      }
      lastScannedQR.current = null;
      setResult(scannedBarcode.data);
    } else {
      // For QR scans: debounce to avoid repeated API calls.
      if (qrDebounceTimer.current) {
        clearTimeout(qrDebounceTimer.current);
      }
      qrDebounceTimer.current = setTimeout(() => {
        const segments = scannedBarcode.data.split('/').filter(segment => segment !== '');
        const numberStr = segments[segments.length - 1];
        const number = parseInt(numberStr, 10);
        if (lastScannedQR.current !== number) {
          getProductNameFromQR(number);
          lastScannedQR.current = number;
        }
      }, 300); // Adjust debounce delay as needed.
    }
  }, [scannedBarcode, isProcessing]);

  // Main product lookup function with request tracking
  const activeRequestId = useRef(0);
  
  const getProduct = useCallback(async () => {
    const requestId = ++activeRequestId.current;
    setIsProcessing(true);
    
    try {
      // Skip if we have a valid product for this UPC or newer request exists
      if (result === lastScannedUPC.current && product || requestId !== activeRequestId.current) {
        return;
      }
      setDrawerVisible(false);

      // Start DB and API calls concurrently.
      const dbPromiseCall = getProductByUPCWithTimeout(result, 300);
      const apiPromise = getScanData({ upcCode: result });

      // Await DB result first.
      const dbRes = await dbPromiseCall;
      if (dbRes) {
        setDbProduct(dbRes);
        setDrawerVisible(true);
        successSound.play();
        setErrorModalVisible(false);
      } else {
        setDbProduct(null);
      }

      // Now await API result.
      const scanData = await apiPromise;
      if (!scanData || scanData.length === 0 || scanData?.status === 404) {
        if (!dbRes) {
          setErrorModalVisible(true);
          setErrorMessage("Oops! We couldn't find the product you scanned. Please try a different one.");
          errorSound.play();
          resetProductStates();
        }
        return;
      }
      setProduct(Array.isArray(scanData) ? scanData[0] : scanData?.data);
      lastScannedUPC.current = result;
      setDrawerVisible(true);

      // Fetch extra details if needed.
      if (scanData.length > 0 && Number(scanData[0].activeGsSystem) >= 4) {
        const completeProductDetails = await getProductDataFromUPC(result);
        setProductDetails(completeProductDetails);
        if (!dbRes) {
          successSound.play();
        }
        await addRecentScanToDB(completeProductDetails);
        const queryParams = new URLSearchParams(location.search);
        queryParams.set("upc", result);
        navigate(`${location.pathname}?${queryParams.toString()}`, { replace: true });
        setErrorMessage("");
        setErrorModalVisible(false);
        if (!localStorage.getItem("firstScanModalShown")) {
          setFirstScanModalVisible(true);
          localStorage.setItem("firstScanModalShown", "true");
        }
      } else {
        successSound.play();
      }
    } catch (error) {
      console.error(error);
      setErrorMessage("Oops! We couldn't find the product you scanned. Please try a different one.");
      setErrorModalVisible(true);
      resetProductStates();
      errorSound.play();
      return;
    } finally {
      if (requestId === activeRequestId.current) {
        setIsProcessing(false);
      }
    }
  }, [result, location, navigate]);

  // Trigger product lookup when result changes.
  useEffect(() => {
    if (result) {
      getProduct();
    }
  }, [result, getProduct]);

  // On initial render, check if a UPC is in the URL query.
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const upc = queryParams.get('upc');
    if (upc) {
      setResult(upc);
    }
  }, [location.search]);

   useEffect(() => {
      let timer;
      if (errorMessage !== " " && isErrorModalVisible) {
        setIsProcessing(true);
        timer = setTimeout(() => {
          setErrorModalVisible(false);
          setErrorMessage('');
          resetProductStates();
          setIsProcessing(false);
        }, 2500);
      }
      return () => {
        if (timer) clearTimeout(timer);
      };
    }, [errorMessage, isErrorModalVisible]);

  const handleResetCamera = useCallback(() => {
    // Cancel any pending requests by incrementing request ID
    activeRequestId.current++;
    setIsProcessing(false);
    
    setErrorModalVisible(false);
    setResult(null);
    setDrawerVisible(false);
    setProduct(null);
    setDbProduct(null);
    setProductDetails(null);
    lastScannedUPC.current = null;
    lastScannedQR.current = null;
    setErrorMessage('');
    navigate(location.pathname, { replace: true });
  }, [location, navigate]);

  return (
    <div className="scan-wrap">
      <div className="video-wrapper">
        <div className="scan-overlay">
          <div className="scan-box"></div>
        </div>
      </div>

      {!isDrawerVisible && !invalidCode && !isErrorModalVisible && <FilterSelector position="bottom" size={null} />}

      {!invalidCode && !isErrorModalVisible && <BottomSheet
        visible={isDrawerVisible}
        onClose={handleBottomSheetClose}
        handleResetCamera={handleResetCamera}
        productData={product}
        productDetails={productDetails}
        productDbData={dbProduct}
      />}

      <Drawer
          className={"product-bottom-sheet z-index-max"}
          placement="bottom"
          rootClassName='bottom-sheet-wrap'
          closable={true}
          mask={false}
          open={isErrorModalVisible || invalidCode}
          height={"auto"}
          destroyOnClose={true}
          closeIcon={null}
          headerStyle={{ display: 'none' }}
          bodyStyle={{ touchAction: 'none' }} 
        >
              <div className='d-flex align-items-center justify-content-start bottom-sheet-product-card gap-2'>
                    <img
                        className='d-inline-block p-1 greyscale img-fluid'
                        src={placeholderimg}
                        alt={"Invalid Barcode"}
                    />
                    {isErrorModalVisible && <div className='d-flex flex-column px-1'>
                        <h5 className='text-dark font-primary error-text pe-2'>
                              GreenChoice has not scored this item yet.
                        </h5>
                    </div> }
                    {invalidCode && <div className='d-flex flex-column px-1'>
                        <h5 className='text-dark font-primary error-text pe-2'>
                           We can only scan barcodes and GreenChoice QR shelf tags.
                        </h5>
                    </div> }   
              </div>
      </Drawer>
    </div>
  );
};

export default ScanResults;
