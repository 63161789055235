import React from "react";
import { Modal } from "antd";
import { Link } from "react-router-dom";
import { getClientInfoFromLocalStorage } from "../../Utils/ClientInfo";
import './index.scss'

const DisclaimerModal = ({ open, setOpen }) => {
  const clientInfo = getClientInfoFromLocalStorage();
  return <Modal open={open} onCancel={() => setOpen(false)} destroyOnClose={true} footer={null}>
     <div className="disclaimer-modal"> 
        <h4>📃️️ Disclaimer</h4>
        <p>The information provided is for informational purposes only. Although we attempt to provide accurate and up-to-date information, no guarantee is made to that effect. Likewise, you should always refer to the physical product for the most accurate information and warnings, and for additional information, contact the manufacturer. We are independently owned and the opinions expressed on this app and GreenChoice Shelf Tags are our own interpretation of trusted sources. The descriptions used to describe each GreenScore tier are an opinion of GreenChoice. They only qualify the product rating according to the GreenScore method. Under no circumstances, do these descriptions refer directly to the product’s quality. This app does not offer medical advice, or attempt to diagnose or treat any health problem, disease, or dietary condition. Nor does it guarantee products are allergy-safe. The information contained herein should NOT be used as a substitute for the advice of an appropriately qualified and licensed physician or other healthcare provider. For more information on how we rate products, please see <Link to="/greenscore">Our GreenScore Method</Link>. You can also review our <Link to="/terms">Terms of Service</Link>. {clientInfo?.fullName}  assumes no liability for inaccuracies or misstatements about products. Thank you for using GreenChoice to inform your decisions and to make healthy, climate-friendly choices!</p>
     </div>
  </Modal>;
};

export default DisclaimerModal;
