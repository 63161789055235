import React from 'react'
import logo from "../../Assets/Images/Svgs/logo.svg"

const Loader = () => {
  return (
    <div className="loader">
       <img src={logo} alt="GreenChoice" />
    </div >
  )
}

export default Loader