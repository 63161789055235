import React, { useEffect, useState } from 'react'
import { addFavoriteToDB, getAllFavoritesFromDB, removeFavoriteFromDB } from '../../db/db'
import { toast } from 'react-toastify'
import "./index.scss"
import { handleAddToFav } from '../../Gtag'

const AddToFavorite = ({ product }) => {
    const [isFavorite, setIsFavorite] = useState(false);
    const [favoriteCount, setFavoriteCount] = useState(0);
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        var x = window.matchMedia("(max-width: 767px)");
        if (x.matches) {
            setIsMobile(true);
        } else {
            setIsMobile(false);
        }
    }, []);

    // Check if the product is already in favorites
    useEffect(() => {
        const checkFavorite = async () => {
            const favorites = await getAllFavoritesFromDB();
            setFavoriteCount(favorites.length);
            const found = favorites.some(fav => fav?.id === product?.upcCode);
            setIsFavorite(found);
        };
        checkFavorite();
    }, [product]);

    // Add to favorite and update state
    const handleAddToFavorite = async () => {
        if (favoriteCount >= 100) {
            toast.warning("You can only save up to 100 products as favorites.");
            return;
        }
        await addFavoriteToDB(product);
        setIsFavorite(true);
        toast.success("Product added to favorites.");
        handleAddToFav(product.productName);
    };

    // Remove from favorite and update state
    const handleRemoveFromFavorite = async () => {
        await removeFavoriteFromDB(product.upcCode);
        setIsFavorite(false);
        setFavoriteCount(favoriteCount - 1);
        toast.success("Product removed from favorites.");
    };

    return (
        <>
            {isMobile && <>
                {!isFavorite ? (
                    <button onClick={handleAddToFavorite} className='favorite-btn font-primary'>
                        <div>
                            <svg className="" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#4e4e4e" class="bi bi-star" viewBox="0 0 16 16">
                            <path d="M2.866 14.85c-.078.444.36.791.746.593l4.39-2.256 4.389 2.256c.386.198.824-.149.746-.592l-.83-4.73 3.522-3.356c.33-.314.16-.888-.282-.95l-4.898-.696L8.465.792a.513.513 0 0 0-.927 0L5.354 5.12l-4.898.696c-.441.062-.612.636-.283.95l3.523 3.356-.83 4.73zm4.905-2.767-3.686 1.894.694-3.957a.56.56 0 0 0-.163-.505L1.71 6.745l4.052-.576a.53.53 0 0 0 .393-.288L8 2.223l1.847 3.658a.53.53 0 0 0 .393.288l4.052.575-2.906 2.77a.56.56 0 0 0-.163.506l.694 3.957-3.686-1.894a.5.5 0 0 0-.461 0z"/>
                            </svg>
                        </div>
                        <span className='px-2 pt-1'> Add to Favorite </span>
                    </button>
                ) : (
                    <button onClick={handleRemoveFromFavorite} className='favorite-btn font-primary'>
                        <div>
                            <svg className="" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#4e4e4e" class="bi bi-star-fill" viewBox="0 0 16 16">
                            <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/>
                            </svg>
                        </div>
                        <span className='px-2 pt-1'> Remove from Favorite </span>
                    </button>
                )}
            </>}
        </>
    );
}

export default AddToFavorite