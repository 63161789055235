export const filterData = {
  diets: [
    {
      diet: "Pescatarian",
      id: 1
    },
    {
      diet: "Vegan",
      id: 2
    },
    {
      diet: "Vegetarian",
      id: 3
    },
    {
      diet: "Paleo",
      id: 4
    },
    {
      diet: "No Added Sugar",
      id: 5
    },
    {
      diet: "Gluten Free",
      id: 7
    },
    {
      diet: "Keto",
      id: 8
    },
    {
      diet: "Low FODMAP",
      id: 9
    }
  ],
  allergies: [
    {
      allergy: "No Soy",
      id: 38
    },
    {
      allergy: "No Shellfish",
      id: 39
    },
    {
      allergy: "No Gluten",
      id: 40
    },
    {
      allergy: "No Lactose",
      id: 41
    },
    {
      allergy: "No Sesame",
      id: 42
    },
    {
      allergy: "No Tree Nut",
      id: 43
    },
    {
      allergy: "No Peanut",
      id: 44
    },
    {
      allergy: "No Egg",
      id: 45
    },
    {
      allergy: "No Milk",
      id: 46
    },
    {
      allergy: "No Fish",
      id: 47
    },
    {
      allergy: "No Wheat",
      id: 48
    },
    {
      allergy: "No Tomato",
      id: 49
    },
    {
      allergy: "No Melon",
      id: 50
    },
    {
      allergy: "No Almond",
      id: 51
    },
    {
      allergy: "No Corn",
      id: 53
    },
    {
      allergy: "No Chia",
      id: 54
    },
    {
      allergy: "No Stevia",
      id: 55
    },
    {
      allergy: "No Sodium Benzoate",
      id: 56
    }
  ],
  ingredients: [
    {
      "ingredient": "No Artificial Colors",
      "id": 1
    },
    {
      "ingredient": "No Artificial Flavors",
      "id": 2
    },
    {
      "ingredient": "No Artificial Preservatives",
      "id": 3
    },
    {
      "ingredient": "No Artificial Sweeteners",
      "id": 4
    },
    {
      "ingredient": "No Nitrates",
      "id": 5
    },
    {
      "ingredient": "No MSG",
      "id": 6
    },
    {
      "ingredient": "No Carrageenan",
      "id": 7
    },
    {
      "ingredient": "No Refined Oils",
      "id": 8
    },
    {
      "ingredient": "No Caffeine",
      "id": 9
    },
    {
      "ingredient": "No Added Sugar",
      "id": 12
    },
    {
      "ingredient": "100% Whole Grain",
      "id": 10,
    },
    {
      "ingredient": "Contains Whole Grain",
      "id": 11,
    }
  ],
  sustainability: [
    {
      "sustainability": "Minimally Processed",
      "id": 1
    },
    {
      "sustainability": "100% Organic Ingredients",
      "id": 2
    },
    {
      "sustainability": "95%+ Organic Ingredients",
      "id": 3
    },
    {
      "sustainability": "70%+ Organic Ingredients",
      "id": 4
    },
    {
      "sustainability": "Contains Organic Ingredients",
      "id": 5
    },
    {
      "sustainability": "Low Carbon Footprint",
      "id": 6
    },
    {
      "sustainability": "Low Water Footprint",
      "id": 7
    },
    {
      "sustainability": "No Palm Oil",
      "id": 8
    }
  ],
  nutrients: [
    {
      "nutrient": "Low Sodium",
      "id": 1
    },
    {
      "nutrient": "Low Saturated Fat",
      "id": 2
    },
    {
      "nutrient": "Low Cholesterol",
      "id": 3
    },
    {
      "nutrient": "Low Carbohydrate",
      "id": 4
    },
    {
      "nutrient": "High Carbohydrate",
      "id": 5
    },
    {
      "nutrient": "High Vitamin A",
      "id": 6
    },
    {
      "nutrient": "High Vitamin C",
      "id": 7
    },
    {
      "nutrient": "High Protein",
      "id": 8
    },
    {
      "nutrient": "High Fiber",
      "id": 9
    },
    {
      "nutrient": "High Iron",
      "id": 10
    },
    {
      "nutrient": "High Potassium",
      "id": 11
    },
    {
      "nutrient": "High Vitamin D",
      "id": 12
    },
    {
      "nutrient": "High Calcium",
      "id": 13
    }
  ],
  values: [
    // {
    //     value: "Organic",
    //     id: 101
    // },
    {
      value: "Highly nutritious",
      id: 102
    },
    {
      value: "Climate-friendly",
      id: 103
    },
    {
      value: "Plant-based",
      id: 104
    },
    {
      value: "Real ingredients",
      id: 105
    }
  ]
};
