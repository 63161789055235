export const handleFaqClick = (question) => {
  window.gtag("event", "faq_click", {
    event_label: "FAQ Question",
    event_value: question,
  });
};

export const handleRadialClick = (productName) => {
  window.gtag("event", "product_page_GS_radial_click", {
    event_label: "Product Page GS Radial",
    event_value: productName,
  });
};

export const handleProductCardClick = (carousel, productName) => {
  if (carousel === "Product pairings") {
    window.gtag("event", "product_pairings_product_click", {
      event_label: `Product pairings product card`,
      event_value: `${productName}`,
    });
  } else {
    window.gtag("event", "better_for_you_product_click", {
      event_label: `Better-for-you product card`,
      event_value: `${productName}`,
    });
  }

};

export const handleSlideTrack = (productName) => {
  window.gtag("event", "product_page_image_slide", {
    event_label: "PDP Banner Image Slider",
    event_value: productName,
  });
};

export const handleTabsClicked = (tabname) => {
  window.gtag("event", "product_page_tabs_clicked", {
    event_label: "Product Page Tabs Clicked",
    event_value: tabname,
  });
};

export const handleDisclaimerClicked = () => {
  window.gtag("event", "disclaimer_cta_clicked", {
    event_label: "Disclaimer",
    event_value: "Disclaimer CTA click",
  });
};

export const handleReportClicked = (product) => {
  window.gtag("event", "report_cta_clicked", {
    event_label: "Bug Report",
    event_value: `Report CTA click for ${product}`,
  });
};

export const handleBetterAlternatives = (product) => {
  window.gtag("event", "better_alternatives_slide", {
    event_label: "Better-for-you alternatives",
    event_value: `Better alternative carousel for ${product}`,
  });
};

export const handleSimilarProducts = (product) => {
  window.gtag("event", "product_pairings_slide", {
    event_label: "Product pairings",
    event_value: `Product pairings carousel for ${product}`,
  });
};

export const handleProcessingGSClicked = (product) => {
  window.gtag("event", "gs_chart_processing_rating_clicked", {
    event_label: "Product Page GS Chart",
    event_value: `Processing GS click for ${product}`,
  });
};

export const handleNutritionGSClicked = (product) => {
  window.gtag("event", "gs_chart_nutrition_rating_clicked", {
    event_label: "Product Page GS Chart",
    event_value: `Nutrition GS click for ${product}`,
  });
};

export const handleFoodSafetyGSClicked = (product) => {
  window.gtag("event", "gs_chart_food_safety_rating_clicked", {
    event_label: "Product Page GS Chart",
    event_value: `Food safety GS click ${product}`,
  });
};

export const handleEnvironmentGSClicked = (product) => {
  window.gtag("event", "gs_chart_environment_rating_clicked", {
    event_label: "Product Page GS Chart",
    event_value: `Environment GS click ${product}`,
  });
};

export const handleDailyValueModal = (product) => {
  window.gtag("event", "gs_chart_daily_value_popup", {
    event_label: "Product Page GS Chart",
    event_value: `GS daily value modal clicked for ${product}`,
  });
}

export const handleFoodSafetyModal = (product) => {
  window.gtag("event", "gs_chart_food_safety_popup", {
    event_label: "Product Page GS Chart",
    event_value: `GS food safety modal clicked for ${product}`,
  });
}

export const handleProcessingModal = (product) => {
  window.gtag("event", "gs_chart_processing_popup", {
    event_label: "Product Page GS Chart",
    event_value: `GS processing modal clicked for ${product}`,
  });
}

export const handleEnvironmentModal = (product) => {
  window.gtag("event", "gs_chart_environment_popup", {
    event_label: "Product Page GS Chart",
    event_value: `GS environment footprint modal clicked for ${product}`,
  });
}

export const handleEncourageNutrition = (nutrient) => {
  window.gtag("event", "gs_chart_encourage_nutrient_clicked", {
    event_label: "Product Page Encourage Nutrition",
    event_value: `Encourage ${nutrient}`,
  });
}

export const handleLimitNutrition = (nutrient) => {
  window.gtag("event", "gs_chart_limit_nutrient_clicked", {
    event_label: "Product Page Limit Nutrition",
    event_value: `Limit ${nutrient}`,
  });
}

export const handleCreateProfile = () => {
  window.gtag("event", "create_profile_clicked", {
    event_label: "Create My Dietary Profile",
    event_value: `User ID: ${JSON.parse(localStorage.getItem("userId"))}`,
  });
}

export const handleSetPreference = () => {
  window.gtag("event", "set_preference_cta", {
    event_label: "Set Preference Link",
    event_value: `Set preference link clicked`,
  });
}

export const handleScoring = () => {
  window.gtag("event", "scoring_method_cta", {
    event_label: "Scoring Method Link",
    event_value: `Scoring method link clicked`,
  });
}

export const handleFiltersDefined = () => {
  window.gtag("event", "filters_defined_cta", {
    event_label: "Filters Defined Link",
    event_value: `Filters defined link clicked`,
  });
}

export const handleSelectedFilters = (value) => {
  window.gtag("event", "user_filters", {
    event_label: "Selected filters",
    event_value: value,
  });
}

export const handleProductType = (value) => {
  window.gtag("event", "product_type", {
    event_label: "Product type",
    event_value: value,
  });
}

export const handleItemScanned = (type, code) => {
  window.gtag("event", "scan_result", {
    event_label: "item_scanned",
    event_value: `${type}: ${code}`,
  });
}

export const handleAddToFav = (productName) => {
  window.gtag("event", "add_to_favorite", {
    event_label: "Favorite",
    event_value: productName,
  });
}

export const handleSearch = (searched) => {
  window.gtag("event", "searched_item", {
    event_label: "Search",
    event_value: searched,
  });
}