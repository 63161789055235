import React, { useState } from 'react';
import { Drawer } from 'antd';
import { useFilters } from '../../Context/FiltersContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSliders } from '@fortawesome/free-solid-svg-icons';
import "./index.scss"
import Filters from '../../Pages/Filters';

const SearchBottomSheet = () => {
    const [visible, setVisible] = useState(false);
    const { selectedFilters } = useFilters()

    const onClose = () => {
        setVisible(false);
    }

    const onOpen = () => {
        setVisible(true)
    }

    return (
        <div className='search-filter-wrap'>
        <button onClick={onOpen} className='filter-selector-wrap'>
            {Object.keys(selectedFilters).length > 0 ?
                     <div className='position-relative'>
                        <FontAwesomeIcon
                             icon={faSliders}
                             className={'px-0'}
                             size={'sm'}
                        />
                         <span className='pe-3'> Filters </span>
                         <span className='selected-filters-number'>{Object.keys(selectedFilters).length}</span>
                    </div>
                    : <p  className='add-filter-wrap'>
                          <FontAwesomeIcon icon={faSliders} size='sm' />
                          <span> Add Filter</span>
                     </p>}
        </button>
            <Drawer
                className='search-bottom-sheet'
                placement="bottom"
                closable={true}
                onClose={onClose}
                visible={visible}
                height={550}
                closeIcon={null}
            >
                <Filters setVisible={setVisible} position='bottomSheet'/>
            </Drawer>
        </div>
    );
};

export default SearchBottomSheet;
