import React from "react";
import "./index.scss";
import { Link, useLocation } from "react-router-dom";
import { handleCreateProfile } from "../../Gtag";

const Preference = () => {
  const currentLocation = useLocation();

  const returnUrl = currentLocation.pathname + currentLocation.search;

  return (
    <div className="preference-setup-wrapper d-flex mb-4">
      <h3 className="text-center text-heading mb-0">
        Set your dietary preferences
      </h3>
      <Link to={`/preferences-setup?returnUrl=${encodeURIComponent(returnUrl)}`} onClick={() => handleCreateProfile()} className="pref-set-up-btn">
        CREATE MY DIETARY PROFILE
      </Link>
    </div>
  );
};

export default Preference;
