import React from 'react'
import { Navigation } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react';
import ProductCard from '../ProductCard';
import { handleBetterAlternatives, handleSimilarProducts } from '../../Gtag';
import 'swiper/css';
import 'swiper/css/navigation';
import './index.scss'

const ProductCarousel = ({ carousel, items, product }) => {
  return (
    <Swiper
      modules={[Navigation]}
      key={carousel === 'similar' ? 'pairing' : 'bfy'}
      spaceBetween={carousel === 'similar' ? 50 : 25}
      slidesPerView={carousel === 'similar' ? 1.5 : 1.75}
      onSlideChange={() => carousel === 'similar' ? handleSimilarProducts(product?.productName) : handleBetterAlternatives(product?.productName)}
      navigation={true}
      loop={false}
      watchOverflow={true}
      breakpoints={{
        767: {
          slidesPerView: 3,
        },
        1020: {
          slidesPerView: 4
        }
      }}
    >
      {items.length > 0 && items.slice(0, 25).map(item => {
        return <SwiperSlide key={item?.upc}>
          <ProductCard carousel={carousel} product={item} />
        </SwiperSlide>
      })}
    </Swiper>
  )
}

export default ProductCarousel