import React, { createContext, useContext, useEffect, useMemo, useRef, useState } from 'react';
import {
  BarcodeCapture,
  barcodeCaptureLoader,
  BarcodeCaptureOverlay,
  BarcodeCaptureSettings,
  ScanIntention,
  Symbology,
} from '@scandit/web-datacapture-barcode';
import {
  CameraSwitchControl,
  Camera,
  configure,
  DataCaptureContext,
  DataCaptureView,
  FrameSourceState,
  VideoResolution,
  FocusGestureStrategy
} from '@scandit/web-datacapture-core';

// SDK status constants
const SDKStatus = {
  Uninitialized: 'uninitialized',
  Initializing: 'initializing',
  Ready: 'ready',
  Error: 'error',
};

// Create a context to hold the SDK reference and loading status
const SDKContext = createContext({
  loading: false,
  loaded: false,
  sdk: null,
});

// Controller class that initializes and manages the Scandit SDK
class SDKController {
  constructor() {
    this.state = {
      status: SDKStatus.Uninitialized,
      isScanning: false,
      isCameraOn: false,
    };
  }

  async initialize() {
    if (this.state.status !== SDKStatus.Uninitialized) return;
    try {
      this.state.status = SDKStatus.Initializing;
      await configure({
        libraryLocation: "https://cdn.jsdelivr.net/npm/@scandit/web-datacapture-barcode@7.1.0/sdc-lib/",
        licenseKey: process.env.REACT_APP_SCANDIT_API_KEY,
        moduleLoaders: [barcodeCaptureLoader()],
      });
      await this.initializeComponents();
      this.state.status = SDKStatus.Ready;
    } catch (error) {
      this.state.status = SDKStatus.Error;
      throw error;
    }
  }

  async cleanup(isStrictModeCleanup = false) {
    if (isStrictModeCleanup) return;
    if (this.state.barcodeCapture) {
      await this.state.barcodeCapture.setEnabled(false);
    }
    if (this.state.context && this.state.context.frameSource) {
      await this.state.context.frameSource.switchToDesiredState(FrameSourceState.Off);
    }
    if (this.state.context) {
      await this.state.context.dispose();
      await this.state.context.removeAllModes();
    }
    if (this.state.view) {
      this.state.view.detachFromElement();
    }
    if (this.state.host) {
      this.state.host.remove();
    }
    this.state = {
      status: SDKStatus.Uninitialized,
      isScanning: false,
      isCameraOn: false,
    };
  }

  async enableScanning(enabled) {
    if (this.state.status !== SDKStatus.Ready) return;
    if (enabled && !this.state.isScanning) {
      await this.enableCamera(true);
      await this.state.barcodeCapture.setEnabled(true);
      this.state.isScanning = true;
    } else if (!enabled && this.state.isScanning) {
      await this.state.barcodeCapture.setEnabled(false);
      this.state.isScanning = false;
    }
  }

  async enableCamera(enabled) {
    if (this.state.status !== SDKStatus.Ready || this.state.isCameraOn === enabled) return;
    if (this.state.context && this.state.context.frameSource) {
      await this.state.context.frameSource.switchToDesiredState(enabled ? FrameSourceState.On : FrameSourceState.Off);
      this.state.isCameraOn = enabled;
    }
  }

  async enableSymbology(symbology, enabled) {
    if (this.state.status !== SDKStatus.Ready) return;
    if (this.state.settings) {
      this.state.settings.enableSymbology(symbology, enabled);
      await this.state.barcodeCapture.applySettings(this.state.settings);
    }
  }

  connectToElement(element) {
    if (!this.state.host || this.state.status !== SDKStatus.Ready) return;
    this.state.host.style.display = 'block';
    element.append(this.state.host);
  }

  detachFromElement() {
    if (!this.state.host) return;
    this.state.host.style.display = 'none';
    document.body.append(this.state.host);
  }

  addBarcodeCaptureListener(listener) {
    if (this.state.barcodeCapture) {
      this.state.barcodeCapture.addListener(listener);
    }
  }

  removeBarcodeCaptureListener(listener) {
    if (this.state.barcodeCapture) {
      this.state.barcodeCapture.removeListener(listener);
    }
  }

  getStatus() {
    return this.state.status;
  }

  getEnabledSymbologies() {
    return this.state.settings ? this.state.settings.enabledSymbologies : undefined;
  }

  // Create a host element for the SDK view
  createHostElement() {
    const element = document.createElement('div');
    element.style.display = 'none';
    element.style.width = '100%';
    element.style.height = '100%';
    document.body.append(element);
    return element;
  }

  // Initialize all the SDK components
  async initializeComponents() {
    this.state.cameraSwitchControl = new CameraSwitchControl();
    this.state.view = new DataCaptureView();
    this.state.host = this.createHostElement();
    this.state.view.connectToElement(this.state.host);
    this.state.view.addControl(this.state.cameraSwitchControl);

    this.state.settings = new BarcodeCaptureSettings();
    this.state.settings.codeDuplicateFilter = 2000;
    this.state.settings.scanIntention = ScanIntention.Smart;
    this.state.settings.enableSymbologies([
      Symbology.EAN8,
      Symbology.UPCE,
      Symbology.EAN13UPCA,
      Symbology.QR,
    ]);

    this.state.context = await DataCaptureContext.create();
    this.state.camera = Camera.default;
    const cameraSettings = BarcodeCapture.recommendedCameraSettings;
    cameraSettings.zoomFactor = 1.5; 
    cameraSettings.preferredResolution = VideoResolution.FullHD;
    cameraSettings.focusGestureStrategy = FocusGestureStrategy.AutoOnLocation;
    await this.state.camera.applySettings(cameraSettings);
    await this.state.context.setFrameSource(this.state.camera);

    this.state.barcodeCapture = await BarcodeCapture.forContext(this.state.context, this.state.settings);
    this.state.barcodeCapture.onSuccessFeedback = () => {};
    await this.state.view.setContext(this.state.context);
    this.state.overlay = await BarcodeCaptureOverlay.withBarcodeCaptureForView(this.state.barcodeCapture, this.state.view);
  }
}

// SDKProvider component that initializes the SDK and provides its state via context
export const SDKProvider = ({ children }) => {
  const [status, setStatus] = useState(SDKStatus.Uninitialized);
  const sdkRef = useRef(new SDKController());
  const sdk = sdkRef.current;

  useEffect(() => {
    const initialize = async () => {
      try {
        await sdk.initialize();
        setStatus(sdk.getStatus());
      } catch (error) {
        console.error('Failed to initialize SDK:', error);
        setStatus(SDKStatus.Error);
      }
    };

    initialize();

    return () => {
      sdk.cleanup();
    };
  }, [sdk]);

  const value = useMemo(() => ({
    loading: status === SDKStatus.Initializing,
    loaded: status === SDKStatus.Ready,
    sdk,
  }), [status, sdk]);

  return <SDKContext.Provider value={value}>{children}</SDKContext.Provider>;
};

// Hook to access the SDK context
export const useSDK = () => {
  return useContext(SDKContext);
};
