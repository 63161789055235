import React from 'react';
import './index.scss';

const Suggestions = ({ suggestions, handleMove }) => {
    const categoryTitles = {
        productcategory: 'Product Category',
        productsubcategory: 'Product Subcategory',
        producttype: 'Product Type',
    };

    const renderSection = (title, data) => {
        if (data.length === 0) return null; // Skip rendering if no data

        return (
            <div className='my-3' key={title}>
                <p className="section-title mb-1 mt-2">
                    {categoryTitles[title]}
                </p>
                <div className='d-flex align-items-center flex-wrap gap-2'>
                    {data.map((item, index) => (
                        <button
                            key={index}
                            onClick={() => handleMove(item, title)}
                            className="suggestion-item"
                        >
                            {item}
                        </button>
                    ))}
                </div>
            </div>
        );
    };

    return (
        <div className="suggestions-container">
            {Object.keys(suggestions).map((key) =>
                renderSection(key, suggestions[key])
            )}
        </div>
    );
};

export default Suggestions;
