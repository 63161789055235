import React from 'react'
import "./index.scss"
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faChartPie, faSliders } from '@fortawesome/free-solid-svg-icons'
import { handleFiltersDefined, handleScoring, handleSetPreference } from '../../Gtag'
import AddToFavorite from '../AddToFavorite'

const MoreOptions = ({product}) => {
    return (
        <div className='more-options-wrap'>
            <div className='container'>
                <h5>More Options</h5>
                <ul>
                    <li> 
                        <AddToFavorite product={product} />
                    </li>
                    <li onClick={handleSetPreference}>
                        <Link to="/preferences-setup">
                            <FontAwesomeIcon icon={faSliders} fontSize={16} color='#4e4e4e' />
                            <span className='px-2'> Set your preferences </span>
                        </Link>
                    </li>
                    <li onClick={handleScoring}>
                        <Link to="/greenscore">
                            <FontAwesomeIcon icon={faChartPie} fontSize={16} color='#4e4e4e' />
                            <span className='px-2'>  Scoring method </span>
                        </Link>
                    </li>
                    <li onClick={handleFiltersDefined}>
                        <Link to="/glossary">
                            <FontAwesomeIcon icon={faBars} fontSize={16} color='#4e4e4e' />
                            <span className='px-2'> Dietary filters defined </span>
                        </Link>
                    </li>
                </ul>
            </div>
        </div>
    )
}

export default MoreOptions