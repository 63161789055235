import React, { useEffect, useState } from "react";
import { useFilters } from "../../Context/FiltersContext";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faAngleDown,
    faAngleUp,
    faCheckCircle,
    faExclamationCircle,
    faSliders,
    faTimes,
} from "@fortawesome/free-solid-svg-icons";
import "./index.scss";
import { Modal } from "antd";

const BottomSheetFilters = ({ product, setFiltered }) => {
    const [unmatchedFilters, setUnmatchedFilters] = useState([]);
    const [combinedFilters, setCombinedFilters] = useState([]);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const {
        loadFilters,
        selectedFilters,
        chipColors,
        getFilterLabelById,
        handleRemoveFilter,
        clearFilters,
        checkConflicts,
    } = useFilters();

    const navigate = useNavigate();

    // Load filters and check conflicts
    useEffect(() => {
        loadFilters();
        if (product) {
            checkConflicts(product);
        }
        return () => {
            setUnmatchedFilters([]);
            setCombinedFilters([]);
        };
    }, [product]);

    // Combine matched and unmatched filters
    useEffect(() => {
        if (!selectedFilters || Object.keys(selectedFilters).length === 0) {
            setCombinedFilters([]);
            setUnmatchedFilters([]);
            setFiltered(false);
            return;
        }
        const updateFilters = () => {
            const unmatched = [];
            const matched = [];

            if (selectedFilters && chipColors) {
                Object.keys(selectedFilters).forEach((compositeKey) => {
                    if (chipColors[compositeKey] === "#FCDDDD") {
                        unmatched.push(compositeKey);
                    } else {
                        matched.push(compositeKey);
                    }
                });
            }
            setCombinedFilters([...unmatched, ...matched]);
            setUnmatchedFilters(unmatched);
            if(combinedFilters.length > 0) {
                setFiltered(true);
            } else {
                setFiltered(false);
            }
        };
        updateFilters();
    }, [selectedFilters, chipColors]);

    const showModal = () => setIsModalVisible(true);
    const handleCancel = () => setIsModalVisible(false);

    return (
        <div className="filter-selector-wrap">
            <ul className="px-2 py-1">
                {/* Navigate to preferences setup */}
                <li className="px-3" onClick={() => navigate("/preferences-setup")}>
                    {Object.keys(selectedFilters).length > 0 ? (
                        <FontAwesomeIcon icon={faSliders} className="px-1" />
                    ) : (
                        <p className="add-filter-wrap">
                            <FontAwesomeIcon icon={faSliders} size="sm" />
                            <span className=""> Add Filter</span>
                        </p>
                    )}
                </li>

                {/* Render combined filters */}
                {combinedFilters
                    .map((compositeKey) => {
                        const isUnmatched = unmatchedFilters.includes(compositeKey);
                        return (
                            <li
                                key={compositeKey}
                                className="d-flex align-items-center"
                                style={{
                                    background: isUnmatched ? "#fcdddd" : "#eee",
                                }}
                                onClick={() =>
                                    handleRemoveFilter(
                                        compositeKey.split("_")[0],
                                        parseInt(compositeKey.split("_")[1])
                                    )
                                }
                            >
                                <FontAwesomeIcon
                                    icon={isUnmatched ? faExclamationCircle : faCheckCircle}
                                    style={{
                                        fontSize: 15,
                                        color: isUnmatched ? "#F15754" : "#17B169",
                                    }}
                                />
                                <span className="me-2 ms-1">
                                    {getFilterLabelById(
                                        compositeKey.split("_")[0],
                                        parseInt(compositeKey.split("_")[1])
                                    )}
                                </span>
                                <FontAwesomeIcon
                                    icon={faTimes}
                                    color="#4e4e4e"
                                    style={{ padding: "1px 6px 0 5px" }}
                                />
                            </li>
                        );
                    })}


                {/* Reset Filters option */}
                {Object.keys(selectedFilters).length > 0 && (
                    <li className="px-3" onClick={clearFilters}>
                        Reset Filters
                    </li>
                )}
            </ul>
            {combinedFilters.length > 0 && <div className="px-3 pt-3 pb-2 bg-white rounded-top-3 rounded-end-3 rounded-bottom-0">
                <p className="mb-0">
                    <FontAwesomeIcon
                        icon={unmatchedFilters.length > 0 ? faExclamationCircle : faCheckCircle}
                        style={{
                            fontSize: 15,
                            color: unmatchedFilters.length > 0 ? "#F15754" : "#17B169",
                        }}
                    />
                    <span className="ms-1 ps-1 fs-6">{unmatchedFilters.length <= 0 ? "This item matches your profile." : "This item doesn't match."}</span>
                    <span onClick={showModal} className="text-decoration-underline text-secondary px-1"> Why?</span>
                </p>
            </div>}

            <Modal
                visible={isModalVisible}
                onCancel={handleCancel}
                footer={null}
                className="py-2"
            >
                <h5 className="mt-4 mb-3 font-primary text-dark fw-bolder"> Red filters do not match this item.</h5>
                <ul className="mb-0">
                    {unmatchedFilters.map((key) => (
                        <li className="font-primary text-dark fs-6 my-2" key={key}>
                            <FontAwesomeIcon icon={faExclamationCircle} className="text-danger pe-2" />
                            {getFilterLabelById(
                                key.split("_")[0],
                                parseInt(key.split("_")[1])
                            )}
                        </li>
                    ))}
                </ul>
                <ul>
                    {combinedFilters
                        .filter((key) => !unmatchedFilters.includes(key))
                        .map((key) => (
                            <li className="font-primary text-dark fs-6 my-2" key={key}>
                                <FontAwesomeIcon icon={faCheckCircle} className="text-success pe-2" />
                                {getFilterLabelById(
                                    key.split("_")[0],
                                    parseInt(key.split("_")[1])
                                )}
                            </li>
                        ))}
                </ul>
            </Modal>

        </div>
    );
};

export default BottomSheetFilters;
