import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBarcode } from '@fortawesome/free-solid-svg-icons';
import ProductCardHorizontal from '../../Components/ProductCardHorizontal';
import { getAllRecentlyScannedFromDB, getProductFromDB } from '../../db/db';
import { toast } from 'react-toastify';
import './index.scss';

// Function to render the empty state
const EmptyState = () => {
    return (
        <div className="empty-state">
            <div className="empty-state-icon">
                <FontAwesomeIcon icon={faBarcode} />
            </div>
            <div className="empty-state-text">
                <h2>NO PRODUCTS</h2>
                <p>Scan or search your first food</p>
            </div>
        </div>
    );
};

const History = () => {
    const [products, setProducts] = useState([]);

    const getProducts = async () => {
        try {
            const res = await getAllRecentlyScannedFromDB()
            if (res) {
                setProducts(res)
            }
        } catch (error) {
            console.log(error)
            toast.error(error || error.message || "Something went wrong!")
        }
    }

    useEffect(() => {
        getProducts();
    }, [])

    return (
       <>
            <div className="history-container">
                <div className='fixed-head'><h3 className='mb-0'> History </h3></div>
                <div className='product-list-column'>{products.length > 0 ? products.map(product => <ProductCardHorizontal productData={product} key={`history-list-${product?.product?.upcCode}`} />) : EmptyState()}</div>
            </div>
       </>
    );
};

export default History;
