// Function to get client information from local storage
export const getClientInfoFromLocalStorage = () => {
  try {
    const clientInfo = localStorage.getItem('clientInfo');
    return clientInfo ? JSON.parse(clientInfo) : null;
  } catch (error) {
    console.error('Error getting client info from local storage:', error);
    return null;
  }
};

// Function to store client information in local storage
export const setClientInfoToLocalStorage = (clientInfo) => {
  try {
    localStorage.setItem('clientInfo', JSON.stringify(clientInfo));
  } catch (error) {
    console.error('Error setting client info to local storage:', error);
  }
};

// Function to fetch client information from the API endpoint
export const getClientInfo = async (locationId) => {
  try {
    const response = await fetch("/api/client-info");
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    let clientInfo = await response.json();
    if (locationId) {
      clientInfo = { ...clientInfo, location: locationId };      
    }
    setClientInfoToLocalStorage(clientInfo);
    return clientInfo;
  } catch (error) {
    console.error('Error fetching client info from API:', error);
    let storedClientInfo = getClientInfoFromLocalStorage();
    if (storedClientInfo && locationId) {
      storedClientInfo = { ...storedClientInfo, location: locationId };
    }
    return storedClientInfo || { name: '', fullName: '', location: '', status: 'enabled', scanEnabled: "" };
  }
};
