import { Progress } from 'antd';
import React, { useEffect, useState } from 'react';
import { getRadialColor } from '../../Utils/CommonFunctions';
import { Link } from 'react-router-dom';
import PlaceholderImg from '../../Assets/Images/placeholder.png'
import "./index.scss"

const BottomSheetCard = ({ image, productName, totalGs, url }) => {
    const [gsFeel, setGsFeel] = useState("");

    useEffect(() => {
        let score = Math.round(Number(totalGs));
        if (score >= 90) {
            setGsFeel("Great 😍");
        } else if (score >= 75 && score <= 89) {
            setGsFeel("Good 😊");
        } else if (score >= 50 && score <= 74) {
            setGsFeel("Okay 🙂");
        } else if (score >= 26 && score <= 49) {
            setGsFeel("Limit 😐");
        } else if (score <= 24) {
            setGsFeel("Avoid 😶");
        } else {
            setGsFeel("");
        }
    }, []);

    return (
        <>
          <Link 
            state={"bottomSheet"}
            to={`/p/${url}`} 
            className='text-dark text-decoration-none'>
                <div className='d-flex align-items-center justify-content-start bottom-sheet-product-card gap-2'>
                        <img
                            className='d-inline-block'
                            src={(image && image !== "") ? image : PlaceholderImg}
                            alt={productName}
                        />
                        <div className='d-flex flex-column'>
                            <p className="text-dark font-primary product-title">{productName}</p>
                            <div className='d-inline-block'>
                                <Progress
                                    type="circle"
                                    percent={Math.round(totalGs)}
                                    size={48}
                                    className="bottom-sheet-progress-score font-primary fw-bold mt-2"
                                    strokeWidth="12"
                                    strokeLinecap="round"
                                    format={(percent) => percent + ""}
                                    strokeColor={`${getRadialColor(Math.round(totalGs))}`}
                                />
                                <p className="gs-feel ps-1">{gsFeel}</p>
                            </div> 
                        </div>
                </div>
          </Link >
        </>
    );
};

export  default BottomSheetCard ;
