import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import { getAllFavoritesFromDB } from '../../db/db';
import { toast } from 'react-toastify';
import ProductCardHorizontal from '../../Components/ProductCardHorizontal';
import './index.scss';

// Function to render the empty state
const EmptyState = () => {
    return (
        <div className="empty-state">
            <div className="empty-state-icon">
                <FontAwesomeIcon icon={faStar} />
            </div>
            <div className="empty-state-text">
                <h2>NO FAVORITES</h2>
                <p>Save a product to your favorites to start your list!</p>
            </div>
        </div>
    );
};

const Favorite = () => {
    const [products, setProducts] = useState([]);

    const getProducts = async () => {
        try {
            const res = await getAllFavoritesFromDB()
            setProducts(res)
        } catch (error) {
            console.log(error)
            toast.error(error || error.message || "Something went wrong!")
        }
    }

    useEffect(() => {
        getProducts();
    }, [])

    return (
        <>
            <div className="favorites-container">
                <div className='fixed-head'> <h3 className='mb-0'> Favorites </h3></div>
                <div className='product-list-column'>{products.length > 0 ? products.map(product => <ProductCardHorizontal productData={product} key={`fav-list-${product?.product?.upcCode}`} />) : EmptyState()}</div>
            </div>
        </>
    );
};

export default Favorite;
