import React, { useEffect, useMemo, useRef } from "react";
import { useSDK } from "../../scandItSetUp";
import "./index.scss";

export default function CameraScanner({ onBarcodeScanned }) {
  const host = useRef(null);
  const { loaded, sdk } = useSDK();

  // Barcode capture listener
  const onScan = useMemo(() => ({
    didScan: async (_, session) => {
      if (session.newlyRecognizedBarcode) {
        if (onBarcodeScanned) {
          onBarcodeScanned({
            data: session.newlyRecognizedBarcode?._data,
            type: session.newlyRecognizedBarcode?._symbology,
          });
        }
      }
    },
  }), [onBarcodeScanned]);

  useEffect(() => {
    async function onMount() {
      if (loaded && host.current) {
        sdk.connectToElement(host.current);
        await sdk.enableCamera(true);
        await sdk.enableScanning(true);
        sdk.addBarcodeCaptureListener(onScan);
      }
    }
    onMount();
    return () => {
      if (loaded) {
        sdk.removeBarcodeCaptureListener(onScan);
        sdk.detachFromElement();
      }
    };
  }, [loaded, sdk, onScan]);

  return (
    <div className="camera-scanner-container">
      <div ref={host} className="camera-wrapper"/>
    </div>
  );
}
