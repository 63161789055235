import React, { useEffect } from 'react'
import { useFilters } from '../../Context/FiltersContext'
import "./index.scss"
import { useNavigate } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle, faSliders, faTimes } from '@fortawesome/free-solid-svg-icons'
import { uniqueId } from 'lodash'

const FilterSelector = ({ position = null, size = null }) => {
    const positionCls = position ? position : ""
    const { loadFilters, selectedFilters, getFilterLabelById, handleRemoveFilter, clearFilters } = useFilters()

    const navigate = useNavigate();

    useEffect(() => {
        loadFilters();
    }, [])

    return (
        <div className={`filter-selector-wrap ${positionCls}`}>
            <ul>
                <li className='px-3' onClick={() => navigate("/preferences-setup")}>
                    {Object.keys(selectedFilters).length > 0 ?
                        <div className='position-relative'>
                            <FontAwesomeIcon
                                icon={faSliders}
                                className={size && size === "sm" ? 'px-0' : 'px-2'}
                                size={size && size === "sm" ? 'sm' : 'lg'}
                            />
                            {size && size === "sm" && <span className='pe-3'> Filters </span>}
                            {size && size === "sm" && <span className='selected-filters-number'>{Object.keys(selectedFilters).length}</span>}
                        </div>
                        : <p className='add-filter-wrap'>
                            <FontAwesomeIcon icon={faSliders} size='sm' />
                            <span> Add Filter</span>
                        </p>}
                </li>
                {!size && size !== "sm" && Object.keys(selectedFilters).map(item => {
                    return <li
                        key={uniqueId()}
                        className='d-flex align-items-center'
                        onClick={() => handleRemoveFilter(
                            item.split("_")[0], parseInt(item.split("_")[1])
                        )}>
                        {!positionCls && <FontAwesomeIcon icon={faCheckCircle} color='#17B169' />}
                        <span className='me-2 ms-1'>{getFilterLabelById(item.split("_")[0], parseInt(item.split("_")[1]))}</span>
                        <FontAwesomeIcon
                            icon={faTimes}
                            color='#4e4e4e'
                            style={{ padding: "0 6px 1px 5px" }}
                        />
                    </li>
                })}
                {!size && size !== "sm" && Object.keys(selectedFilters).length > 0 && <li className='px-3' onClick={clearFilters}> Reset Filters </li>}
            </ul>
        </div>
    )
}

export default React.memo(FilterSelector)