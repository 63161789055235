import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHistory, faSearch, faStar } from "@fortawesome/free-solid-svg-icons";
import barcode from "../../Assets/Images/Svgs/barcode-scan.svg";
import { Link, useLocation } from "react-router-dom";
import "./index.scss";
import introJs from "intro.js";
import "intro.js/introjs.css";

const Navigation = () => {
  const location = useLocation();
  const historyRef = React.useRef(null);
  const scanRef = React.useRef(null);
  const favoriteRef = React.useRef(null);
  const searchRef = React.useRef(null);

  React.useEffect(() => {
    // Delay execution to ensure refs are populated
    const timer = setTimeout(() => {
      // Check if onboarding was already completed
      if (localStorage.getItem("onboardingComplete")) return;

      // Verify all refs are available
      if (!scanRef.current || !historyRef.current || !favoriteRef.current || !searchRef.current) {
        return;
      }

      const intro = introJs();
      intro.setOptions({
        tooltipClass: "my-custom-tooltip",
        escapeHtml: false,
        steps: [
          {
            element: scanRef.current,
            intro:
              "<strong>Start a new UPC or QR code scan instantly</strong> <br> Experience fast, seamless product discovery with just one tap.",
          },
          {
            element: historyRef.current,
            intro:
              "<strong>Review your recent scans and searches</strong> <br> Track every product you've explored in one convenient place.",
          },
          {
            element: favoriteRef.current,
            intro:
              "<strong>View your handpicked favorites</strong> <br> Easily access and manage the products you love.",
          },
          {
            element: searchRef.current,
            intro:
              "<strong>Search by your dietary preferences</strong> <br> Quickly find products that meet your specific dietary needs.",
          },
        ],
      });

      // Mark onboarding as complete when the tour finishes or exits.
      intro.oncomplete(() => localStorage.setItem("onboardingComplete", "true"));
      intro.onexit(() => localStorage.setItem("onboardingComplete", "true"));

      intro.start();
    }, 0);

    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="navigation">
      <div
        className={`nav-item ${location.pathname === "/history" ? "active" : ""}`}
        ref={historyRef}
      >
        <Link
          to="/history"
          className="d-flex text-decoration-none flex-column justify-content-center align-items-center"
        >
          <FontAwesomeIcon icon={faHistory} className="nav-icon" />
          <span className="nav-label">History</span>
        </Link>
      </div>
      <div
        className={`nav-item ${location.pathname === "/scan" ? "active" : ""}`}
        ref={scanRef}
      >
        <Link
          to="/scan"
          className="d-flex text-decoration-none flex-column justify-content-center align-items-center"
        >
          <img src={barcode} alt="Barcode Scanner" className="nav-icon scanner" />
          <span className="nav-label">Scan</span>
        </Link>
      </div>
      <div
        className={`nav-item ${location.pathname === "/favorite" ? "active" : ""}`}
        ref={favoriteRef}
      >
        <Link
          to="/favorite"
          className="d-flex text-decoration-none flex-column justify-content-center align-items-center"
        >
          <FontAwesomeIcon icon={faStar} className="nav-icon" />
          <span className="nav-label">Favorites</span>
        </Link>
      </div>
      <div
        className={`nav-item ${location.pathname === "/search" ? "active" : ""}`}
        ref={searchRef}
      >
        <Link
          to="/search"
          className="d-flex text-decoration-none flex-column justify-content-center align-items-center"
        >
          <FontAwesomeIcon icon={faSearch} className="nav-icon" />
          <span className="nav-label">Search</span>
        </Link>
      </div>
    </div>
  );
};

export default Navigation;
