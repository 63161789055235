import React from 'react';
import { getRadialColor } from '../../Utils/CommonFunctions';
import { Progress } from 'antd';
import { Link } from 'react-router-dom';
import PlaceHolderImg from '../../Assets/Images/placeholder.png'
import './index.scss';

const SearchProductCard = (productData) => {
    const { product } = productData;

    return (
        <Link className='text-decoration-none w-100 text-secondary'
            to={{
                pathname: `/p/${product?.productnameurl?.raw}`,
            }}>
            <div className="product-card-vertical">
                <img src={(product.productimage.raw && product.productimage.raw !== "") ? product?.productimage?.raw : PlaceHolderImg} alt={product?.productname?.raw} className="product-image" />
                <div className="product-details">
                    <h4 className="product-name">{product?.productname?.raw}</h4>
                    <div className='d-inline-block product-card-radial'>
                        <Progress
                            type="circle"
                            percent={Math.round(product?.totalgs?.raw)}
                            size={42}
                            className="fw-bold font-primary pt-1 bg-white rounded-circle"
                            strokeWidth="11"
                            strokeLinecap="round"
                            format={(percent) => percent + ""}
                            strokeColor={`${getRadialColor(Math.round(product?.totalgs?.raw))}`}
                        />
                    </div>
                    <p className='text-secondary fw-light mb-0'> {product?.productquantity?.raw} </p>
                </div>
            </div>
        </Link>
    );
};

export default SearchProductCard;
