import React, { useEffect, useState } from 'react';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getRadialColor } from '../../Utils/CommonFunctions';
import { Progress } from 'antd';
import { Link } from 'react-router-dom';
import PlaceHolder from '../../Assets/Images/placeholder.png'
import './index.scss';

const ProductCardHorizontal = ({ productData }) => {
    const { product } = productData;
    const [gsFeel, setGsFeel] = useState("");

    useEffect(() => {
        let score = Math.round(Number(product?.totalGs));
        if (score >= 90) {
            setGsFeel("Great 😍");
        } else if (score >= 75 && score <= 89) {
            setGsFeel("Good 😊");
        } else if (score >= 50 && score <= 74) {
            setGsFeel("Okay 🙂");
        } else if (score >= 26 && score <= 49) {
            setGsFeel("Limit 😐");
        } else if (score <= 24) {
            setGsFeel("Avoid 😶");
        } else {
            setGsFeel("");
        }
    }, []);

    return (
        <Link className='text-decoration-none w-100'
            to={{
                pathname: `/p/${product?.productNameURL}`,
                state: {
                    productDataFromLocation: product,
                },
            }}>
            <div className="product-card-horizontal">
                <img src={(product.productImage && product.productIage !== "") ? product.productImage : PlaceHolder} alt={product.productName} className="product-image" />
                <div className="product-details">
                    <h4 className="product-name">{product.productName}</h4>
                    <p className="product-brand">{product.productBrand}</p>
                    <div className='d-inline-block'>
                        <Progress
                            type="circle"
                            percent={Math.round(product?.totalGs)}
                            size={42}
                            className="fw-bold font-primary mt-2"
                            strokeWidth="12"
                            strokeLinecap="round"
                            format={(percent) => percent + ""}
                            strokeColor={`${getRadialColor(Math.round(product?.totalGs))}`}
                        />
                        <p className="gs-feel ps-1">{gsFeel}</p>
                    </div>
                </div>
                <FontAwesomeIcon className='tap-icon' icon={faAngleRight} />
            </div>
        </Link>
    );
};

export default ProductCardHorizontal;
