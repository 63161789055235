import axios from "axios";
import { HTTPService } from "./service";

// GET PRODUCT DATA FROM UPC
export const getProductDataFromUPC = async (upc) => {
  const baseURL = "https://gc-api-v2.greenchoicenow.com/api"
  const fetchData = async (code) => {
    try {
      const res = await fetch(`${baseURL}/ProductTableMergeds/getProductData?upcCode=${code}`);
      if (res.status === 200) {
        const data = await res.json();
        return data;
      } else {
        return null; // Explicitly return null if the status is not 200
      }
    } catch (error) {
      console.error('Error fetching product data:', error);
      return null; // Return null on error
    }
  };

  // Attempt to fetch data with the original UPC code
  let data = await fetchData(upc);

  // Retry with a modified UPC code if the first attempt fails and the UPC code starts with '0'
  if (!data && upc && upc.length === 12 && upc.startsWith('0')) {
    const modifiedUpc = upc.slice(1);
    data = await fetchData(modifiedUpc);
  }

  // Throw an error if both attempts fail
  if (!data) {
    console.log('Failed to fetch product data');
    return null
  }

  return data;
};

// GET PRODUCT DATA
export const getProductData = async ({ productName, mainSize }) => {
  const params = {};
  if (mainSize) {
    params.size = mainSize;
  }
  params.activegs = 1;

  return await HTTPService({
    method: "GET",
    url: `/ProductTableMergeds/getProductData?productName=${productName}`,
    params: params,
  });
};

// GET OTHER PRODUCT DATA
export const getOtherProductData = async ({ productName, size }) => {
  const params = {};
  if (size) {
    params.size = size;
  }
  params.activegs = 1;

  return await HTTPService({
    method: "GET",
    url: `/ProductTableMergeds/getProductOtherData?productName=${productName}`,
    params: params,
  });
};

// SUBMIT A BUG
export const submitBug = async (data) =>
  await HTTPService({
    method: "POST",
    url: `/UserBugReports`,
    data: data,
  });

//PDP FAQ
export const pdpFaq = async (productCode) =>
  await HTTPService({
    url: `/product_faqs/?filter={"where": {"productCode":"${productCode}"}}`,
    method: "GET",
  });

//CHECK BRAND//
export const brandClaimStatus = async (brand) =>
  await HTTPService({
    url: `/product_brands?filter[where][brand_name]=${brand}`,
    method: "GET",
  });


//SIMILAR PRODUCTS
export const similarProducts = async ({ productNameURL, clientParam, userLocation, filtersParam }) => {
  const queryParams = {
    productName: productNameURL,
    limit: 20,
    client: clientParam,
    ...filtersParam
  };

  if (userLocation !== undefined) {
    queryParams.location = userLocation;
  }

  try {
    const response = await HTTPService({
      method: "GET",
      url: "/ProductTableMergeds/getRelatedProducts",
      params: queryParams,
    });
    return response;
  } catch (error) {
    console.error("Error fetching related products:", error);
    throw error;
  }
};

// GET BETTER-FOR-YOU OPTIONS
export const getBFY = async ({ upcCode, clientParam, userLocation, filtersParam }) => {
  const queryParams = {
    upcCode: upcCode,
    client: clientParam,
    location: userLocation,
    limit: 20,
    ...filtersParam
  };

  try {
    const response = await HTTPService({
      method: "GET",
      url: `/ProductTableMergeds/getBetterForYouProductsUPC`,
      params: queryParams,
    });
    return response;
  } catch (error) {
    console.error("Error fetching related products:", error);
    throw error;
  }
}

export const autoComplete = async (data) =>
  await HTTPService({
    method: "POST",
    url: `/ProductTableMergeds/auto_complete_search`,
    data: data,
    headers: { "content-type": "application/x-www-form-urlencoded" },
  });

export const searchProducts = async (reqBody) => {
  try {
    const res = await HTTPService({
      url: "/ProductTableMergeds/search",
      method: "POST",
      data: reqBody
    })
    return res
  } catch (error) {
    console.log(error)
    throw error
  }
}

export const getProductName = async ({id}) => {
  const queryParams = {
    data_id: id
  }
  try {
    const response = await HTTPService({
      method: "GET",
      url: `/ProductTableMergeds/getProductURL`,
      params: queryParams,
    });
    return response;
  } catch (error) {
    console.log(error)
    throw error
  }
}

export const getScanData = async ({upcCode}) => {
  const queryParams = {
    upcCode: upcCode
  }
  try {
    const response = await HTTPService({
      method: "GET",
      url: `/ProductTableMergeds/getScanData`,
      params: queryParams,
    });
    return response;
  } catch (error) {
    console.log(error)
    throw error
  }
}

export async function getProductByUPC(upcCode) {
  try {
    const { data } = await axios.get(`/api/products/${upcCode}`);
    return data;
  } catch (err) {
    if (err.response?.status === 404) throw new Error('Product not found');
    throw err;
  }
}
